/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import Chart from 'react-apexcharts';
import { Spinner } from 'react-bootstrap';
import { sep } from 'path';
import Aggregates, { AGGREGATETYPES } from '../../api/dashboards';
import './HomePage.scss';
import DateRange from '../../utils/daterangepicker';

interface TodaysBooking {
  total: number;
  totalPax: number;
  adults: number;
  children: number;
  infants: number;
}

function BookingCreated() {
  const [fetchBookingStates, { data, loading }] = useLazyQuery<AGGREGATETYPES>(Aggregates);
  const [dateRange, setDateRange] = useState<string[]>(['', '']);
  const [newBookings, setNetBookings] = useState('');
  const [bookingDiagram, setBookingDiagram] = useState<any>();
  const [bookingDiagramStatus, setBookingDiagramStatus] = useState<any>();
  const [PaxDiagram, setPaxDiagram] = useState<any>();
  useEffect(() => {
    if (dateRange.length > 1) {
      fetchBookingStates({
        variables: {
          startDate: `${dateRange[0]}T00:00:00.233999+00:00`,
          endDate: `${dateRange[1]}T23:59:00.233999+00:00`,
          date: dayjs().format('YYYY-MM-DD'),
        },
      });
    }
  }, [dateRange]);
  const state = {
    options: {
      labels: ['Rezdy', 'Website'],
      colors: ['#4C4D9F', '#95A4FC'],
    },
  };
  const state3 = {
    options: {
      labels: ['Completed', 'Pending', 'Cancelled'],
      colors: ['#4ade80', '#f97316', '#D10000'],
    },
  };
  const state2 = {
    options: {
      labels: ['Adults', 'Children', 'Infants'],
      colors: ['#A1E3CB', '#B1E3FF', '#BCBDC2'],
    },
  };
  const [citiesData, setCitiesData] = useState<TodaysBooking | undefined>();
  const [cityKeys, setCityData] = useState<string[]>([]);
  const [perCity, setPerCity] = useState<any>();
  useEffect(() => {
    if (data?.aggregates[0]) {
      let adults = 0;
      let children = 0;
      let infants = 0;
      data.aggregates.forEach((aggregate) => {
        aggregate.bookingUnits.forEach((unit) => {
          switch (unit.unit.label) {
            case 'Adults':
              adults += unit.quantity;
              break;
            case 'Children':
              children += unit.quantity;
              break;
            case 'Infants':
              infants += unit.quantity;
              break;
          }
        });
      });
      setCitiesData(() => ({
        total: data.aggregates.length,
        totalPax: adults + children + infants, 
        adults,
        children,
        infants,
      }));
      const citiesList = data.aggregates.map((city) => {
        const cityAdults = city.bookingUnits.find(unit => unit.unit.label === 'Adults')?.quantity || 0;
        const cityChildren = city.bookingUnits.find(unit => unit.unit.label === 'Children')?.quantity || 0;
        const cityInfants = city.bookingUnits.find(unit => unit.unit.label === 'Infants')?.quantity || 0;

        return {
          name: city.product.citiesProduct[0].city.name,
          pax: cityAdults + cityChildren + cityInfants, 
          total: 1,
          website: city.source === 'WEBSITE' ? 1 : 0,
          rezdy: city.source === 'REZDY' ? 1 : 0,
          infants: cityInfants,
          children: cityChildren,
          source: city.source,
          adults: cityAdults,
        };
      });
      const seperateByCity = {
        [citiesList[0].name]: {
          name: citiesList[0].name,
          pax: citiesList[0].pax,
          website: 0,
          rezdy: 0,
          source: citiesList[0].source,
          total: 0,
          infants: citiesList[0].infants,
          children: citiesList[0].children,
          adults: citiesList[0].adults,
        },
      };

      citiesList.map((city) => {
        if (seperateByCity[city.name]) {
          seperateByCity[city.name].pax += city.pax;

          seperateByCity[city.name].total += 1;

          if (city.source === 'WEBSITE') {
            seperateByCity[city.name].website += 1;
          }
          if (city.source === 'REZDY') {
            seperateByCity[city.name].rezdy += 1;
          }
          seperateByCity[city.name].adults += city.adults;
          seperateByCity[city.name].infants += city.infants;
          seperateByCity[city.name].children += city.children;
          return;
        }
        // @ts-ignore
        seperateByCity[city.name] = {
          name: city.name,
          pax: city.pax,
          total: 1,
          website: city.website,
          rezdy: city.rezdy,
          infants: city.infants,
          adults: city.adults,
          children: city.children,
        };
      });
      setPerCity(seperateByCity);
      const keys = Object.keys(seperateByCity);
      setCityData(keys);
      setBookingDiagram([
        data?.rezdyTodayBooked.aggregate.count,
        data?.websiteTodayBooked.aggregate.count,
      ]);
      setBookingDiagramStatus([
        data?.bookingsCompleted.aggregate.count,
        data.bookingsPending.aggregate.count,
        data?.bookingsCancelled.aggregate.count,
      ]);
      setPaxDiagram([adults, children, infants]);
      return;
    }
    setCitiesData(() => ({
      total: 0,
      totalPax: 0,
      adults: 0,
      children: 0,
      infants: 0,
    }));
    setCityData([]);
  }, [data]);

  return (
    <div className="dash-cards happening ">
      <div className="dash-header">
        <h1>Bookings by Created At</h1>
        <DateRange setDateRange={setDateRange} createdAt />
        {loading && <Spinner animation="border" />}
      </div>

      <div className="total-values">
        <div className="states ">
          <h2>
            Total Bookings:
            {' '}
            <span className="completed">{citiesData?.total}</span>
          </h2>
          <h2>
            Net Bookings:
            {' '}
            <span className="completed">{data?.netbookings?.aggregate.count}</span>
          </h2>
          <h2>
            OTA Partners:
            {' '}
            <span className="rezdy">
              {' '}
              {data?.rezdyTodayBooked.aggregate.count}
            </span>
          </h2>
          <h2>
            Website:
            {' '}
            <span className="website">
              {' '}
              {data?.websiteTodayBooked.aggregate.count}
            </span>
          </h2>
          <h2>
            Cancelled:
            {' '}
            <span className="cancelled">
              {' '}
              {data?.bookingsCancelled.aggregate.count}
            </span>
          </h2>
          <h2>
            Completed:
            {' '}
            <span className="completed">
              {' '}
              {data?.bookingsCompleted.aggregate.count}
            </span>
          </h2>
          <h2>
            Pax:
            {' '}
            <span>{citiesData?.totalPax}</span>
          </h2>
          <h2>
            Adults:
            {' '}
            <span className="adults">
              {' '}
              {citiesData?.adults}
            </span>
          </h2>
          <h2>
            Children:
            {' '}
            <span className="children">
              {' '}
              {citiesData?.children}
            </span>
          </h2>
          <h2>
            Infants:
            {' '}
            <span className="infants">
              {' '}
              {citiesData?.infants}
            </span>
          </h2>
        </div>
        <div className="round-charts">
          {/* @ts-ignore */}
          <Chart
            options={state.options}
            series={bookingDiagram}
            type="donut"
            width="190"
          />
          {/* @ts-ignore */}
          <Chart
            options={state3.options}
            series={bookingDiagramStatus}
            type="donut"
            width="190"
          />
          {/* @ts-ignore */}
          <Chart
            options={state2.options}
            series={PaxDiagram}
            type="donut"
            width="190"
          />
        </div>
      </div>
      <div className="percity-div">
        {cityKeys?.map((item: any) => (
          <div className="card">
            <h2>{perCity[item].name}</h2>
            <h4>
              Bookings:
              <span className="count">{perCity[item].total}</span>
            </h4>
            <h4>
              Rezdy:
              <span className="count">{perCity[item].rezdy}</span>
            </h4>
            <h4>
              Website:
              <span className="count">{perCity[item].website}</span>
            </h4>
            <h4>
              Pax:
              <span className="count">{perCity[item].pax}</span>
            </h4>
            <h4 className="adults">
              Adults:
              <span className="count">{perCity[item].adults}</span>
            </h4>
            {perCity[item].children > 0 && (
              <h4 className="children">
                Children:
                <span className="count">{perCity[item].children}</span>
              </h4>
            )}
            {perCity[item].infants > 0 && (
              <h4 className="infants">
                Infants:
                <span className="count">{perCity[item].infants}</span>
              </h4>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default BookingCreated;
