/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import Chart from 'react-apexcharts';
import { Spinner } from 'react-bootstrap';
import Aggregates from '../../api/dashboard-tourDate';
import './HomePage.scss';

import DateRange from '../../utils/daterangepicker';

function BookingCreated() {
  const [fetchBookingStates, { data, loading }] = useLazyQuery(Aggregates);
  const [dateRange, setDateRange] = useState(['', '']);
  const [bookingDiagram, setBookingDiagram] = useState();
  const [bookingDiagramStatus, setBookingDiagramStatus] = useState();
  const [PaxDiagram, setPaxDiagram] = useState();
  useEffect(() => {
    if (dateRange.length > 1) {
      fetchBookingStates({
        variables: {
          startDate: dateRange[0],
          endDate: dateRange[1],
          date: dayjs().format('YYYY-MM-DD'),
        },
      });
    }
  }, [dateRange]);
  const state = {
    options: {
      labels: ['Rezdy', 'Website'],
      colors: ['#4C4D9F', '#95A4FC'],
    },
  };
  const state3 = {
    options: {
      labels: ['Completed', 'Pending', 'Cancelled'],
      colors: ['#4ade80', '#f97316', '#D10000'],
    },
  };
  const state2 = {
    options: {
      labels: ['Adults', 'Children', 'Infants'],
      colors: ['#A1E3CB', '#B1E3FF', '#BCBDC2'],
    },
  };
  const [citiesData, setCitiesData] = useState();
  const [cityKeys, setCityData] = useState([]);
  const [perCity, setPerCity] = useState();
  console.log('city data', citiesData)
  useEffect(() => {
    if (data && data.happeningToday[0]) {
      let adults = 0;
      let children = 0;
      let infants = 0;

      data.happeningToday.forEach((item) => {
        item.bookingUnits.forEach((unit) => {
          switch (unit.unit.label) {
            case 'Adults':
              adults += unit.quantity;
              break;
            case 'Children':
              children += unit.quantity;
              break;
            case 'Infants':
              infants += unit.quantity;
              break;
          }
        });
      });
      const totalPax = adults + children + infants;
      setCitiesData(() => ({
        total: data.happeningToday.length || 0,
        totalPax, 
        adults,
        children,
        infants,
      }));
      const citiesList = data.happeningToday
      .filter((city) => !['CANCELLED', 'CANCELLED - OPS', 'REFUND_GS', 'REFUND_OPS', 'REFUND_ATTRACTION'].includes(city?.status || ''))
      .map((city) => {
        let cityAdults = 0;
        let cityChildren = 0;
        let cityInfants = 0;

        city.bookingUnits.forEach((unit) => {
          switch (unit.unit.label) {
            case 'Adults':
              cityAdults += unit.quantity;
              break;
            case 'Children':
              cityChildren += unit.quantity;
              break;
            case 'Infants':
              cityInfants += unit.quantity;
              break;
          }
        });

        return {
          name: city.product.citiesProduct[0].city.name,
          pax: cityAdults + cityChildren + cityInfants, 
          total: 1,
          infants: cityInfants,
          website: city.source === 'WEBSITE' ? 1 : 0,
          rezdy: city.source === 'REZDY' ? 1 : 0,
          source: city.source,
          children: cityChildren,
          adults: cityAdults,
        };
      });
      const seperateByCity = {
        [citiesList[0].name]: {
          name: citiesList[0].name,
          pax: citiesList[0].pax,
          website: 0,
          rezdy: 0,
          total: 0,
          source: citiesList[0].source,
          infants: citiesList[0].infants,
          children: citiesList[0].children,
          adults: citiesList[0].adults,
        },
      };

      citiesList.map((city) => {
        if (seperateByCity[city.name]) {
          seperateByCity[city.name].pax += city.pax;
          seperateByCity[city.name].total += 1;
          if (city.source === 'WEBSITE') {
            seperateByCity[city.name].website += 1;
          }
          if (city.source === 'REZDY') {
            seperateByCity[city.name].rezdy += 1;
          }
          seperateByCity[city.name].adults += city.adults;
          seperateByCity[city.name].infants += city.infants;
          seperateByCity[city.name].children += city.children;
          return;
        }
        seperateByCity[city.name] = {
          name: city.name,
          pax: city.pax,
          total: 1,
          website: city.website,
          rezdy: city.rezdy,
          infants: city.infants,
          adults: city.adults,
          children: city.children,
        };
      });
      setPerCity(seperateByCity);
      const keys = Object.keys(seperateByCity);
      setCityData(keys);
      setBookingDiagram([data.rezdyTodayHappening.aggregate.count, data.websiteTodayHappening.aggregate.count]);
      setBookingDiagramStatus([data.completed.aggregate.count, data.cancelled.aggregate.count, data.cancelled.aggregate.count]);
      setPaxDiagram([adults, children, infants]);
      return;
    }
    setCitiesData(() => ({
      total: 0,
      totalPax: 0,
      adults: 0,
      children: 0,
      infants: 0,
    }));
    setCityData([]);
  }, [data]);
  return (
    <div className="dash-cards happening ">
      <div className="dash-header">
        <h1>Bookings by Tour Date</h1>
        <DateRange setDateRange={setDateRange} createdAt />
        {loading && <Spinner animation="border" />}
      </div>

      <div className="total-values">
        <div className="states ">
          <h2>
            Total Bookings:
            {' '}
            <span className="completed">{citiesData && citiesData.total}</span>
          </h2>
          <h2>
            Net Bookings:
            {' '}
            <span className="completed">{data && data.netbookings && data.netbookings.aggregate.count}</span>
          </h2>
          <h2>
            OTA Partners:
            {' '}
            <span className="rezdy">
              {' '}
              {data && data.rezdyTodayHappening && data.rezdyTodayHappening.aggregate.count}
            </span>
          </h2>
          <h2>
            Website:
            {' '}
            <span className="website">
              {' '}
              {data && data.websiteTodayHappening && data.websiteTodayHappening.aggregate.count}
            </span>
          </h2>
          <h2>
            Cancelled:
            {' '}
            <span className="cancelled">
              {' '}
              {data && data.cancelled && data.cancelled.aggregate.count}
            </span>
          </h2>
          <h2>
            Completed:
            {' '}
            <span className="completed">
              {' '}
              {data && data.completed && data.completed.aggregate.count}
            </span>
          </h2>
          <h2>
            Pax:
            {' '}
            <span>{citiesData && citiesData.totalPax}</span>
          </h2>
          <h2>
            Adults:
            {' '}
            <span className="adults">
              {' '}
              {citiesData && citiesData.adults}
            </span>
          </h2>
          <h2>
            Children:
            {' '}
            <span className="children">
              {' '}
              {citiesData && citiesData.children}
            </span>
          </h2>
          <h2>
            Infants:
            {' '}
            <span className="infants">
              {' '}
              {citiesData && citiesData.infants}
            </span>
          </h2>
        </div>
        <div className="round-charts">
          <Chart options={state.options} series={bookingDiagram} type="donut" width="190" />
          <Chart options={state3.options} series={bookingDiagramStatus} type="donut" width="190" />
          <Chart options={state2.options} series={PaxDiagram} type="donut" width="190" />
        </div>
      </div>
      <div className="percity-div">
        {cityKeys && cityKeys.map((item) => {
          console.log('-');
          return (
            <div className="card">
              <h2>{perCity[item].name}</h2>
              <h4>
                Bookings :
                {' '}
                {perCity[item].total}
                {' '}
              </h4>
              <h4>
                Rezdy:
                {' '}
                {perCity[item].rezdy}
              </h4>
              <h4>
                Website:
                {' '}
                {perCity[item].website}
              </h4>
              <h4>
                Pax :
                {' '}
                {perCity[item].pax}
              </h4>
              <h4 className="adults">
                {' '}
                Adults:
                {' '}
                {perCity[item].adults}
              </h4>
              {perCity[item].children > 0 && (
                <h4 className="children">
                  Children :
                  {perCity[item].children}
                </h4>
              )}
              {perCity[item].infants > 0 && (
                <h4 className="infants">
                  Infants
                  {perCity[item].infants}
                </h4>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BookingCreated;
